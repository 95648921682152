import React from 'react';
import * as logger from '../services/log';
import { Error404Page, Error500Page } from 'tabler-react';
import history from '../history';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false
        };
    }

    componentDidCatch(error, errorInfo) {
        try {
            // Catch errors in any components below and re-render with error message
            this.setState({
                error: true
            });

            if (errorInfo && typeof errorInfo === "object") {
                errorInfo = JSON.stringify(errorInfo);
            }

            logger.logError(`Error fallback screen entered. Error Info: ${errorInfo}`, error);

            if (process.env.NODE_ENV !== "development") {
                window.location.href = "/unexpected-error";
            }
        }
        catch (e) {
            logger.logError("Error in <ErrorFallback> screen", e);
        }
    }

    render() {
        if (this.state.error) {
            // Error path
            return (<div className="page text-center">
                <div className="container">
                    <h1 className="h1 mt-0 mb-4 display-1 text-muted mb-5">500</h1>
                    <h2 className="h2 mt-0 mb-4">Oops, you just found an error</h2>
                    <h4 className="h4 mt-0 mb-4 text-muted font-weight-normal mb-7">Please let one of our staff know</h4>
                    <button onClick={() => {window.location.href = "/"}} className="btn btn-primary"><i className="fe fe-home mr-2"></i>Dashboard</button>
                </div>
            </div>);
        }
        // Normally, just render children
        return this.props.children;
    }
}

export { ErrorBoundary };