import React, { useState, useEffect, useRef } from 'react';
import SiteWrapper from '../SiteWrapper.react';
import {
    Page,
    Card,
    Grid,
    Form,
    Button,
    Label
} from "tabler-react";
import { AgGridReact } from "ag-grid-react";
import { CustomLoadingOverlay } from '../CustomLoadingOverlay';
import history from '../../history';
import { useFormik } from 'formik';
import DatePicker from "react-datepicker";
import { CustomDateInput } from '../CustomDateInput';
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import "./NewDiscountCampaign.scss"

const NewDiscountCampaign = () => {

    const [loading, setLoading] = useState(false);
    const [gridHeight, setGridHeight] = useState(0);
    const [columns, setColumns] = useState([]);
    const [products, setProducts] = useState([]);
    const gridApi = useRef(null);
    const gridColumnApi = useRef(null);
    const [formSaving, setFormSaving] = useState(false);

    const PAGE_NAME = "New Discount Campaign";

    useEffect(() => {
        window.addEventListener("resize", handleGridSize);

        handleGridSize();

        setColumns(getColumns());

        return () => {
            window.removeEventListener("resize", handleGridSize);
        }
    }, []);

    const backBtnHandler = () => {
        history.goBack();
    }

    const handleGridSize = () => {

        // number of pixels to offset vertically
        let DesktopVerticalOffset = 320;

        // currently the navbar and footer are the same height accross all widths (e.g. desktop first design). 
        // This method will need modification if these components change.

        if (window.innerWidth < 768) {
            DesktopVerticalOffset -= 115; // footer
        }

        let gridHeight = window.innerHeight - DesktopVerticalOffset;

        setGridHeight(gridHeight);
    }

    const getColumns = () => {

        let columns = [];

        columns.push({
            field: 'name',
            colId: "name",
            headerName: 'Name',
            tooltipField: "name",
            width: 600,
            sortable: true,
            filterParams: {
                suppressMiniFilter: true
            },
            filter: "agTextColumnFilter",
        });

        columns.push({
            field: "sku",
            colId: "sku",
            hide: true,
            headerName: "Shop SKU",
            sortable: false,
            filterParams: {
                suppressMiniFilter: true
            }
        });

        return columns;
    }

    const isFirstColumn = (params) => {
        var displayedColumns = params.columnApi.getAllDisplayedColumns();
        var thisIsFirstColumn = displayedColumns[0] === params.column;
        return thisIsFirstColumn;
    }

    const defaultColDef = {
        headerCheckboxSelection: isFirstColumn,
        checkboxSelection: isFirstColumn,
        resizable: true,
        sortable: true,
        filter: true,
        menuTabs: ['filterMenuTab'],
        filterParams: {
            clearButton: true
        }
    };

    const onGridReady = (params) => {
        gridApi.current = params.api;
        gridColumnApi.current = params.columnApi;
        refreshProducts();
    };

    const refreshProducts = () => {

    }

    const validate = values => {
        const errors = {};

        if (values.name === null || values.name === undefined || values.name.trim() === "") {
            errors.nameError = "Required";
        }

        return errors;
    }

    const formik = useFormik({
        initialErrors: {},
        initialValues: {
            name: "",
            scheduleType: "start_immediately",
            fromDate: null,
            toDate: null
        },
        onSubmit: async (values) => {
            console.log("Form submitted", values);
        },
        validate
    });

    const frameworkComponents = {
        customLoadingOverlay: CustomLoadingOverlay
    }

    return (<SiteWrapper>
        <Page.Content>

            <div className="new-discount-campaign">

                <div onClick={() => backBtnHandler()} className="back-btn anchor"><i style={{ fontSize: "0.9em" }} className="fas fa-chevron-left"></i> Back</div>

                <Grid.Row cards>

                    <Grid.Col sm={12}>


                        <Card loading={loading}>
                            <Card.Header>

                                <h4>{PAGE_NAME}</h4>

                            </Card.Header>

                            <Card.Body>
                                <Form autocomplete="off" onSubmit={formik.handleSubmit}>

                                    <Form.Group label="Name">
                                        <Form.Input
                                            type="text"
                                            disabled={formSaving}
                                            value={formik.values.name || ""}
                                            autocomplete="off"
                                            onBlur={formik.handleBlur}
                                            name="name"
                                            error={formik.touched.name && formik.errors.nameError}
                                            onChange={formik.handleChange} />

                                    </Form.Group>

                                    <Form.Group label="Starting">

                                        <Form.Radio
                                            isInline
                                            disabled={formSaving}
                                            label="Now"
                                            name="scheduleType"
                                            value="start_immediately"
                                            onChange={(e) => {
                                                formik.setFieldValue("fromDate", null, false);
                                                formik.setFieldValue("toDate", null, false);
                                                formik.handleChange(e);
                                            }}
                                            checked={formik.values.scheduleType === "start_immediately"}
                                        />
                                        <Form.Radio
                                            isInline
                                            disabled={formSaving}
                                            label="Schedule"
                                            name="scheduleType"
                                            value="schedule"
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                            }}
                                            checked={formik.values.scheduleType === "schedule"}
                                        />

                                        {
                                            formik.values.scheduleType === "schedule" ?
                                                <div style={{ marginLeft: 20, display: "flex", flexDirection: "row" }}>
                                                    <div style={{ width: 225 }}>
                                                        <small>From Date</small>
                                                        <DatePicker
                                                            dateFormat="MMM/dd/yyyy HH:mm"
                                                            timeFormat="HH:mm"
                                                            customInput={<CustomDateInput />}
                                                            maxDate={new Date()}
                                                            selected={formik.values.fromDate}
                                                            onChange={(date) => formik.setFieldValue("fromDate", date, false)}
                                                            isClearable
                                                            shouldCloseOnSelect
                                                            timeIntervals={30}
                                                            showTimeSelect />
                                                    </div>
                                                    <div style={{ width: 225, marginLeft: 20 }}>
                                                        <small>To Date</small>
                                                        <DatePicker
                                                            dateFormat="MMM/dd/yyyy HH:mm"
                                                            timeFormat="HH:mm"
                                                            customInput={<CustomDateInput />}
                                                            maxDate={new Date()}
                                                            selected={formik.values.toDate}
                                                            onChange={(date) => formik.setFieldValue("toDate", date, false)}
                                                            isClearable
                                                            shouldCloseOnSelect
                                                            timeIntervals={30}
                                                            showTimeSelect />
                                                    </div>
                                                </div>
                                                : null
                                        }
                                    </Form.Group>

                                    <div>
                                        <div>
                                            <Button type="submit" onClick={formik.handleSubmit} loading={formSaving} color="success">Save</Button>
                                        </div>
                                    </div>
                                </Form>

                            </Card.Body>


                        </Card>
                    </Grid.Col>

                    <Grid.Col sm={12}>
                        <Card loading={loading}>
                            <Card.Header>

                                <h4>Product Preview</h4>

                            </Card.Header>

                            <Card.Body>
                                <div
                                    id="productsGrid"
                                    style={{
                                        minHeight: 300,
                                        height: gridHeight
                                    }}
                                    className="ag-theme-alpine">
                                    <AgGridReact
                                        columnDefs={columns}
                                        animateRows={false}
                                        enableBrowserTooltips
                                        enableCellTextSelection
                                        defaultColDef={defaultColDef}
                                        rowSelection="multiple"
                                        onGridReady={onGridReady}
                                        loadingOverlayComponent="customLoadingOverlay"
                                        frameworkComponents={frameworkComponents}
                                        rowData={products}
                                        multiSortKey="ctrl"
                                    />
                                </div>
                            </Card.Body>
                        </Card>
                    </Grid.Col>
                </Grid.Row>
            </div>

        </Page.Content>
    </SiteWrapper>);
}

export { NewDiscountCampaign };