import React from 'react';

class CustomDateInput extends React.Component {

    placeHolder = () => {
        return <div className="calendarIconPlaceholder"> ___/__/____ __:__ <i className="fa fa-calendar calendarIcon" /></div>
    }

    render() {

        return (
            <button
                className="calendarBtn"
                onClick={this.props.onClick}>
                {this.props.value || this.placeHolder()}
            </button>
        )
    }
}

export { CustomDateInput };