import React from 'react';
import { withRouter, Switch, Route } from 'react-router-dom';
import { Dashboard } from './components/Dashboard';
import { Apps } from './components/Apps';
import { Support } from './components/Support';
import { AccountInfo } from './components/AccountInfo';
import { CategoryMappings as EbayCategoryMappings } from './components/Ebay/CategoryMappings';
import { CategoryMappings as TradeMeCategoryMappings } from './components/TradeMe/CategoryMappings';
import { Shipping as EbayShipping } from './components/Ebay/Shipping';
import { Shipping as TradeMeShipping } from './components/TradeMe/Shipping';
import { Shipping as KoganShipping } from './components/Marketplace/Shipping';
import { Currency } from './components/Currency';
import { PaymentInstructions as TradeMePaymentInstructions } from './components/TradeMe/PaymentInstructions';
import { Returns } from './components/Ebay/Returns';
import { Feedback as EbayFeedback } from './components/Ebay/Feedback';
import { Feedback as TradeMeFeedback } from './components/TradeMe/Feedback';
import { InventoryLocations } from './components/Ebay/InventoryLocations';
import { ItemSpecifics } from './components/Ebay/ItemSpecifics';
import { Authorisation as EbayAuthorisation } from './components/Ebay/Authorisation';
import { Authorisation as AmazonAuthorisation } from './components/Amazon/Authorisation';
import { Authorisation as TradeMeAuthorisation } from './components/TradeMe/Authorisation';
import { Authorisation as CatchAuthorisation } from './components/Catch/Authorisation';
import { Authorisation as KoganAuthorisation } from './components/Kogan/Authorisation';
import { Instructions as ShoppingFeedInstructions } from './components/Instructions';
import { CustomRules } from './components/CustomRules';
import { FeedHistory as AmazonFeedHistory } from './components/Amazon/FeedHistory';
import { EditTemplateMappings as AmazonEditTemplateMappings } from './components/EditTemplateMappings';
import { EditTemplateMappings as ShoppingFeedEditTemplateMappings } from './components/EditTemplateMappings';
import { EditTemplateMappings as CatchEditTemplateMappings } from './components/EditTemplateMappings';
import { EditTemplateMappings as KoganEditTemplateMappings } from './components/EditTemplateMappings';
import { General as EbayGeneral } from './components/Ebay/General';
import { General as TradeMeGeneral } from './components/TradeMe/General';
import { General as AmazonGeneral } from './components/Marketplace/General';
import { General as CatchGeneral } from './components/Marketplace/General';
import { General as KoganGeneral } from './components/Marketplace/General';
import Login from './components/Login';
import { Products as EbayProducts } from './components/Ebay/Products/Products';
import { Products as TradeMeProducts } from './components/TradeMe/Products/Products';
import { Products as AmazonProducts } from './components/Products/Products';
import { Products as CatchProducts } from './components/Products/Products';
import { Products as KoganProducts } from './components/Products/Products';
import { Templates as CatchTemplates } from './components/Marketplace/Templates';
import { Templates as KoganTemplates } from './components/Marketplace/Templates';
import { Templates as AmazonTemplates } from './components/Marketplace/Templates';
import { Install as TradeMeInstall } from './components/TradeMe/Install';
import { Install as EbayInstall } from './components/Ebay/Install';
import { Install as AmazonInstall } from './components/Amazon/Install';
import { Install as CatchInstall } from './components/Catch/Install';
import { Install as KoganInstall } from './components/Kogan/Install';
import { preAuthPages, categoryMappingProfileType } from './constants';
import PreAuthLayout from './components/PreAuthLayout';
import history from './history';
import { Error404Page, Error500Page } from 'tabler-react';
import { appType } from './constants';
import { AuthorisedRoute } from './AuthorisedRoute';
import { useSelector } from "react-redux";
import { AuthoriseStatus } from './components/AuthoriseStatus';
import { Templates } from './components/Templates';
import { CategoryMappingsList } from './components/CategoryTaxonomyMapping/CategoryMappingsList';
import { CategoryMappings as CategoryTaxonomyMappings } from './components/CategoryTaxonomyMapping/CategoryMappings';
import Subscription  from './components/Subscription';
import { SubscriptionConfirm } from './components/SubscriptionConfirm';
import * as authUtility from './utilities/authUtility';
import { NewDiscountCampaign } from './components/BulkDiscount/NewDiscountCampaign';

const queryString = require('query-string');

const Routes = () => {

    const user = useSelector(state => state.user);

    const tradeMeEnabled = user && user.info && user.info.apps && user.info.apps.findIndex(a => a.id === appType.tradeMe) > -1;
    const ebayAuEnabled = user && user.info && user.info.apps && user.info.apps.findIndex(a => a.id === appType.eBayAU) > -1;
    const ebayUsEnabled = user && user.info && user.info.apps && user.info.apps.findIndex(a => a.id === appType.ebayUS) > -1;
    const ebayUkEnabled = user && user.info && user.info.apps && user.info.apps.findIndex(a => a.id === appType.ebayUK) > -1;
    const ebayCaEnabled = user && user.info && user.info.apps && user.info.apps.findIndex(a => a.id === appType.ebayCA) > -1;
    const ebayIeEnabled = user && user.info && user.info.apps && user.info.apps.findIndex(a => a.id === appType.ebayIE) > -1;
    const amazonAuEnabled = user && user.info && user.info.apps && user.info.apps.findIndex(a => a.id === appType.amazonAU) > -1;
    const amazonUsEnabled = user && user.info && user.info.apps && user.info.apps.findIndex(a => a.id === appType.amazonUs) > -1;
    const amazonUkEnabled = user && user.info && user.info.apps && user.info.apps.findIndex(a => a.id === appType.amazonUk) > -1;
    const amazonCaEnabled = user && user.info && user.info.apps && user.info.apps.findIndex(a => a.id === appType.amazonCa) > -1;
    const catchEnabled = user && user.info && user.info.apps && user.info.apps.findIndex(a => a.id === appType.catch) > -1;
    const koganEnabled = user && user.info && user.info.apps && user.info.apps.findIndex(a => a.id === appType.kogan) > -1;
    const bulkDiscountEnabled = user && user.info && user.info.appId === appType.bulkDiscountManager;

    if (preAuthPages.indexOf(window.location.pathname) > -1) {

        const parsed = queryString.parse(window.location && window.location.search);

        let authToken = authUtility.getAuthToken();

        // if user is logged in, redirect to portal
        if (authToken && !parsed.token && window.location === '/account/login') {
            history.push("/");
        }

        return (
            // Page Layout component wrapper
            <PreAuthLayout>
                <Switch location={window.location}>
                    <Route exact path="/authorise-status" component={AuthoriseStatus} />
                    <Route exact path="/account/login" component={Login} />
                    
                </Switch>
            </PreAuthLayout>
        );
    }

    return (
        <Switch location={window.location}>

            {/* Main */}     
            <Route exact path='/' component={Dashboard} />
            <Route exact path='/support' component={Support} />

            {
                !user.info.appId ? <Route exact path='/apps' component={Apps} /> : null
            }
            
            <Route exact path='/settings/currency' component={Currency} />
            <Route exact path='/settings/my-account' component={AccountInfo} />
            <Route exact path='/page-not-found' component={Error404Page} />
            <Route exact path='/account/subscription' component={Subscription} />
            <Route exact path='/account/subscription/:mode' component={Subscription} />
            <Route exact path='/account/subscription/confirm/:storeType' component={SubscriptionConfirm} />

            {/* Category Mappings */}
            <Route exact path='/settings/category-mappings/google' render={(props) => <CategoryTaxonomyMappings {...props} categoryMappingProfileId={categoryMappingProfileType.google} />} />

            {/* Bulk Discount App */}
            {
                user.info.appId === appType.bulkDiscountManager ? <AuthorisedRoute exact path="/bulk-discount/new-campaign" isAuthorised={bulkDiscountEnabled} render={(props) => <NewDiscountCampaign {...props}  />} /> : null
            }
            
            {/* Generic Shopping Feed */}
            <AuthorisedRoute exact path="/shopping-feeds/:shoppingFeedSlug/instructions" isAuthorised render={(props) => <ShoppingFeedInstructions {...props}  />} />
            <AuthorisedRoute exact path='/shopping-feeds/:shoppingFeedSlug/edit-template-mappings/:templateDefinitionId' isAuthorised render={(props) => <ShoppingFeedEditTemplateMappings {...props} />} />
            <AuthorisedRoute exact path='/shopping-feeds/:shoppingFeedSlug/templates' isAuthorised render={(props) => <Templates {...props} />} />
            <AuthorisedRoute exact path='/shopping-feeds/:shoppingFeedSlug/category-mappings' isAuthorised render={(props) => <CategoryMappingsList {...props} />} />

            {/* TradeMe New Zealand */}
            <AuthorisedRoute exact path="/marketplaces/trademe/authorisation" isAuthorised={tradeMeEnabled} render={(props) => <TradeMeAuthorisation {...props} app={appType.tradeMe} />} />
            <AuthorisedRoute exact path='/marketplaces/trademe/general' isAuthorised={tradeMeEnabled} render={(props) => <TradeMeGeneral {...props} app={appType.tradeMe} />} />
            <AuthorisedRoute exact path='/marketplaces/trademe/shipping' isAuthorised={tradeMeEnabled} render={(props) => <TradeMeShipping {...props} app={appType.tradeMe} />} />
            <AuthorisedRoute exact path='/marketplaces/trademe/payment-instructions' isAuthorised={tradeMeEnabled} render={(props) => <TradeMePaymentInstructions {...props} app={appType.tradeMe} />} />
            <AuthorisedRoute exact path="/marketplaces/trademe/category-mappings" isAuthorised={tradeMeEnabled} render={(props) => <TradeMeCategoryMappings {...props} app={appType.tradeMe} />} />
            <AuthorisedRoute exact path='/marketplaces/trademe/feedback' isAuthorised={tradeMeEnabled} render={(props) => <TradeMeFeedback {...props} app={appType.tradeMe} />} />
            <AuthorisedRoute exact path='/marketplaces/trademe/custom-rules' isAuthorised={tradeMeEnabled} render={(props) => <CustomRules {...props} app={appType.tradeMe} />} />
            <AuthorisedRoute exact path='/marketplaces/trademe/products' isAuthorised={tradeMeEnabled} component={TradeMeProducts} />
            <Route exact path='/install/trademe' component={TradeMeInstall} />

            {/* Kogan Australia / New Zealand */}
            <Route exact path='/install/kogan' render={(props) => <KoganInstall {...props} app={appType.kogan} />} />
            <AuthorisedRoute exact path='/marketplaces/kogan/products' isAuthorised={koganEnabled} render={(props) => <KoganProducts {...props} app={appType.kogan} />} />
            <AuthorisedRoute exact path='/marketplaces/kogan/templates' isAuthorised={koganEnabled} render={(props) => <KoganTemplates {...props} app={appType.kogan} />} />
            <AuthorisedRoute exact path='/marketplaces/kogan/edit-template-mappings/:templateDefinitionId' isAuthorised={koganEnabled} render={(props) => <KoganEditTemplateMappings {...props} app={appType.kogan} />} />
            <AuthorisedRoute exact path='/marketplaces/kogan/general' isAuthorised={koganEnabled} render={(props) => <KoganGeneral {...props} app={appType.kogan} />} />
            <AuthorisedRoute exact path='/marketplaces/kogan/category-mappings' isAuthorised={koganEnabled} render={(props) => <CategoryTaxonomyMappings {...props} showSettingsMenu app={appType.kogan} categoryMappingProfileId={categoryMappingProfileType.ebayAu} />} />
            <AuthorisedRoute exact path='/marketplaces/kogan/shipping' isAuthorised={koganEnabled} render={(props) => <KoganShipping {...props} app={appType.kogan} />} />
            <AuthorisedRoute exact path='/marketplaces/kogan/authorisation' isAuthorised={koganEnabled} render={(props) => <KoganAuthorisation {...props} app={appType.kogan} />} />

            {/* Catch Australia */}
            <Route exact path='/install/catch' render={(props) => <CatchInstall {...props} app={appType.catch} />} />
            <AuthorisedRoute exact path='/marketplaces/catch/products' isAuthorised={catchEnabled} render={(props) => <CatchProducts {...props} app={appType.catch} />} />
            <AuthorisedRoute exact path='/marketplaces/catch/templates' isAuthorised={catchEnabled} render={(props) => <CatchTemplates {...props} app={appType.catch} />} />
            <AuthorisedRoute exact path='/marketplaces/catch/edit-template-mappings/:templateDefinitionId' isAuthorised={catchEnabled} render={(props) => <CatchEditTemplateMappings {...props} app={appType.catch} />} />
            <AuthorisedRoute exact path='/marketplaces/catch/general' isAuthorised={catchEnabled} render={(props) => <CatchGeneral {...props} app={appType.catch} />} />
            <AuthorisedRoute exact path='/marketplaces/catch/category-mappings' isAuthorised={catchEnabled} render={(props) => <CategoryTaxonomyMappings {...props} showSettingsMenu app={appType.catch} categoryMappingProfileId={categoryMappingProfileType.catch} />} />
            <AuthorisedRoute exact path='/marketplaces/catch/authorisation' isAuthorised={catchEnabled} render={(props) => <CatchAuthorisation {...props} app={appType.catch} />} />

            {/* Amazon Australia */}
            <AuthorisedRoute exact path='/marketplaces/amazon-au/authorisation' isAuthorised={amazonAuEnabled} render={(props) => <AmazonAuthorisation {...props} app={appType.amazonAU} />} />
            <AuthorisedRoute exact path='/marketplaces/amazon-au/edit-template-mappings/:templateDefinitionId' isAuthorised={amazonAuEnabled} render={(props) => <AmazonEditTemplateMappings {...props} app={appType.amazonAU} />} />
            <AuthorisedRoute exact path='/marketplaces/amazon-au/templates' isAuthorised={amazonAuEnabled} render={(props) => <AmazonTemplates {...props} app={appType.amazonAU} />} />
            <AuthorisedRoute exact path='/marketplaces/amazon-au/feed-history' isAuthorised={amazonAuEnabled} render={(props) => <AmazonFeedHistory {...props} app={appType.amazonAU} />} />
            <AuthorisedRoute exact path='/marketplaces/amazon-au/general' isAuthorised={amazonAuEnabled} render={(props) => <AmazonGeneral {...props} app={appType.amazonAU} />} />
            <AuthorisedRoute exact path='/marketplaces/amazon-au/products' isAuthorised={amazonAuEnabled} render={(props) => <AmazonProducts {...props}  app={appType.amazonAU} />} />
            <Route exact path='/install/amazon-au' render={(props) => <AmazonInstall {...props} app={appType.amazonAU} />} />

            {/* Amazon USA */}
            <AuthorisedRoute exact path='/marketplaces/amazon-us/authorisation' isAuthorised={amazonUsEnabled} render={(props) => <AmazonAuthorisation {...props} app={appType.amazonUs} />} />
            <AuthorisedRoute exact path='/marketplaces/amazon-us/edit-template-mappings/:templateDefinitionId' isAuthorised={amazonUsEnabled} render={(props) => <AmazonEditTemplateMappings {...props} app={appType.amazonUs} />} />
            <AuthorisedRoute exact path='/marketplaces/amazon-us/templates' isAuthorised={amazonUsEnabled} render={(props) => <AmazonTemplates {...props} app={appType.amazonUs} />} />
            <AuthorisedRoute exact path='/marketplaces/amazon-us/feed-history' isAuthorised={amazonUsEnabled} render={(props) => <AmazonFeedHistory {...props} app={appType.amazonUs} />} />
            <AuthorisedRoute exact path='/marketplaces/amazon-us/general' isAuthorised={amazonUsEnabled} render={(props) => <AmazonGeneral {...props} app={appType.amazonUs} />} />
            <AuthorisedRoute exact path='/marketplaces/amazon-us/products' isAuthorised={amazonUsEnabled} render={(props) => <AmazonProducts {...props} app={appType.amazonUs} />} />
            <Route exact path='/install/amazon-us' render={(props) => <AmazonInstall {...props} app={appType.amazonUs} />} />

            {/* Amazon UK */}
            <AuthorisedRoute exact path='/marketplaces/amazon-uk/authorisation' isAuthorised={amazonUkEnabled} render={(props) => <AmazonAuthorisation {...props} app={appType.amazonUk} />} />
            <AuthorisedRoute exact path='/marketplaces/amazon-uk/edit-template-mappings/:templateDefinitionId' isAuthorised={amazonUkEnabled} render={(props) => <AmazonEditTemplateMappings {...props} app={appType.amazonUk} />} />
            <AuthorisedRoute exact path='/marketplaces/amazon-uk/templates' isAuthorised={amazonUkEnabled} render={(props) => <AmazonTemplates {...props} app={appType.amazonUk} />} />
            <AuthorisedRoute exact path='/marketplaces/amazon-uk/feed-history' isAuthorised={amazonUkEnabled} render={(props) => <AmazonFeedHistory {...props} app={appType.amazonUk} />} />
            <AuthorisedRoute exact path='/marketplaces/amazon-uk/general' isAuthorised={amazonUkEnabled} render={(props) => <AmazonGeneral {...props} app={appType.amazonUk} />} />
            <AuthorisedRoute exact path='/marketplaces/amazon-uk/products' isAuthorised={amazonUkEnabled} render={(props) => <AmazonProducts {...props} app={appType.amazonUk} />} />
            <Route exact path='/install/amazon-uk' render={(props) => <AmazonInstall {...props} app={appType.amazonUk} />} />

            {/* Amazon CA */}
            <AuthorisedRoute exact path='/marketplaces/amazon-ca/authorisation' isAuthorised={amazonCaEnabled} render={(props) => <AmazonAuthorisation {...props} app={appType.amazonCa} />} />
            <AuthorisedRoute exact path='/marketplaces/amazon-ca/edit-template-mappings/:templateDefinitionId' isAuthorised={amazonCaEnabled} render={(props) => <AmazonEditTemplateMappings {...props} app={appType.amazonCa} />} />
            <AuthorisedRoute exact path='/marketplaces/amazon-ca/templates' isAuthorised={amazonCaEnabled} render={(props) => <AmazonTemplates {...props} app={appType.amazonCa} />} />
            <AuthorisedRoute exact path='/marketplaces/amazon-ca/feed-history' isAuthorised={amazonCaEnabled} render={(props) => <AmazonFeedHistory {...props} app={appType.amazonCa} />} />
            <AuthorisedRoute exact path='/marketplaces/amazon-ca/general' isAuthorised={amazonCaEnabled} render={(props) => <AmazonGeneral {...props} app={appType.amazonCa} />} />
            <AuthorisedRoute exact path='/marketplaces/amazon-ca/products' isAuthorised={amazonCaEnabled} render={(props) => <AmazonProducts {...props} app={appType.amazonCa} />} />
            <Route exact path='/install/amazon-ca' render={(props) => <AmazonInstall {...props} app={appType.amazonCa} />} />

            {/*Ebay Australia */}
            <AuthorisedRoute exact path='/marketplaces/ebay-au' isAuthorised={ebayAuEnabled} render={(props) => <EbayAuthorisation {...props} app={appType.eBayAU} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-au/authorisation' isAuthorised={ebayAuEnabled} render={(props) => <EbayAuthorisation {...props} app={appType.eBayAU} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-au/general' isAuthorised={ebayAuEnabled} render={(props) => <EbayGeneral {...props} app={appType.eBayAU} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-au/category-mappings' isAuthorised={ebayAuEnabled} render={(props) => <EbayCategoryMappings {...props} app={appType.eBayAU} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-au/shipping' isAuthorised={ebayAuEnabled} render={(props) => <EbayShipping {...props} app={appType.eBayAU} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-au/returns' isAuthorised={ebayAuEnabled} render={(props) => <Returns {...props} app={appType.eBayAU} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-au/feedback' isAuthorised={ebayAuEnabled} render={(props) => <EbayFeedback {...props} app={appType.eBayAU} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-au/inventory-locations' isAuthorised={ebayAuEnabled} render={(props) => <InventoryLocations {...props} app={appType.eBayAU} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-au/auto-item-specifics' isAuthorised={ebayAuEnabled} render={(props) => <ItemSpecifics {...props} app={appType.eBayAU} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-au/custom-rules' isAuthorised={ebayAuEnabled} render={(props) => <CustomRules {...props} app={appType.eBayAU} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-au/products' isAuthorised={ebayAuEnabled} render={(props) => <EbayProducts {...props} app={appType.eBayAU} />} />
            <Route exact path='/install/ebay-au' render={(props) => <EbayInstall {...props} app={appType.eBayAU} />} />

            {/*Ebay USA */}
            <AuthorisedRoute exact path='/marketplaces/ebay-us/authorisation' isAuthorised={ebayUsEnabled} render={(props) => <EbayAuthorisation {...props} app={appType.ebayUS} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-us/general' isAuthorised={ebayUsEnabled} render={(props) => <EbayGeneral {...props} app={appType.ebayUS} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-us/category-mappings' isAuthorised={ebayUsEnabled} render={(props) => <EbayCategoryMappings {...props} app={appType.ebayUS} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-us/shipping' isAuthorised={ebayUsEnabled} render={(props) => <EbayShipping {...props} app={appType.ebayUS} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-us/returns' isAuthorised={ebayUsEnabled} render={(props) => <Returns {...props} app={appType.ebayUS} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-us/feedback' isAuthorised={ebayUsEnabled} render={(props) => <EbayFeedback {...props} app={appType.ebayUS} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-us/inventory-locations' isAuthorised={ebayUsEnabled} render={(props) => <InventoryLocations {...props} app={appType.ebayUS} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-us/auto-item-specifics' isAuthorised={ebayUsEnabled} render={(props) => <ItemSpecifics {...props} app={appType.ebayUS} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-us/custom-rules' isAuthorised={ebayUsEnabled} render={(props) => <CustomRules {...props} app={appType.ebayUS} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-us/products' isAuthorised={ebayUsEnabled} render={(props) => <EbayProducts {...props} app={appType.ebayUS} />} />
            <Route exact path='/install/ebay-us' render={(props) => <EbayInstall {...props} app={appType.ebayUS} />} />

            {/*Ebay UK */}
            <AuthorisedRoute exact path='/marketplaces/ebay-uk/authorisation' isAuthorised={ebayUkEnabled} render={(props) => <EbayAuthorisation {...props} app={appType.ebayUK} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-uk/general' isAuthorised={ebayUkEnabled} render={(props) => <EbayGeneral {...props} app={appType.ebayUK} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-uk/category-mappings' isAuthorised={ebayUkEnabled} render={(props) => <EbayCategoryMappings {...props} app={appType.ebayUK} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-uk/shipping' isAuthorised={ebayUkEnabled} render={(props) => <EbayShipping {...props} app={appType.ebayUK} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-uk/returns' isAuthorised={ebayUkEnabled} render={(props) => <Returns {...props} app={appType.ebayUK} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-uk/feedback' isAuthorised={ebayUkEnabled} render={(props) => <EbayFeedback {...props} app={appType.ebayUK} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-uk/inventory-locations' isAuthorised={ebayUkEnabled} render={(props) => <InventoryLocations {...props} app={appType.ebayUK} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-uk/auto-item-specifics' isAuthorised={ebayUkEnabled} render={(props) => <ItemSpecifics {...props} app={appType.ebayUK} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-uk/custom-rules' isAuthorised={ebayUkEnabled} render={(props) => <CustomRules {...props} app={appType.ebayUK} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-uk/products' isAuthorised={ebayUkEnabled} render={(props) => <EbayProducts {...props} app={appType.ebayUK} />} />
            <Route exact path='/install/ebay-uk' render={(props) => <EbayInstall {...props} app={appType.ebayUK} />} />

            {/*Ebay Ireland */}
            <AuthorisedRoute exact path='/marketplaces/ebay-ie/authorisation' isAuthorised={ebayIeEnabled} render={(props) => <EbayAuthorisation {...props} app={appType.ebayIE} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-ie/general' isAuthorised={ebayIeEnabled} render={(props) => <EbayGeneral {...props} app={appType.ebayIE} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-ie/category-mappings' isAuthorised={ebayIeEnabled} render={(props) => <EbayCategoryMappings {...props} app={appType.ebayIE} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-ie/shipping' isAuthorised={ebayIeEnabled} render={(props) => <EbayShipping {...props} app={appType.ebayIE} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-ie/returns' isAuthorised={ebayIeEnabled} render={(props) => <Returns {...props} app={appType.ebayIE} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-ie/feedback' isAuthorised={ebayIeEnabled} render={(props) => <EbayFeedback {...props} app={appType.ebayIE} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-ie/inventory-locations' isAuthorised={ebayIeEnabled} render={(props) => <InventoryLocations {...props} app={appType.ebayIE} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-ie/auto-item-specifics' isAuthorised={ebayIeEnabled} render={(props) => <ItemSpecifics {...props} app={appType.ebayIE} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-ie/custom-rules' isAuthorised={ebayIeEnabled} render={(props) => <CustomRules {...props} app={appType.ebayIE} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-ie/products' isAuthorised={ebayIeEnabled} render={(props) => <EbayProducts {...props} app={appType.ebayIE} />} />
            <Route exact path='/install/ebay-ie' render={(props) => <EbayInstall {...props} app={appType.ebayIE} />} />

            {/*Ebay Canada */}
            <AuthorisedRoute exact path='/marketplaces/ebay-ca/authorisation' isAuthorised={ebayCaEnabled} render={(props) => <EbayAuthorisation {...props} app={appType.ebayCA} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-ca/general' isAuthorised={ebayCaEnabled} render={(props) => <EbayGeneral {...props} app={appType.ebayCA} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-ca/category-mappings' isAuthorised={ebayCaEnabled} render={(props) => <EbayCategoryMappings {...props} app={appType.ebayCA} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-ca/shipping' isAuthorised={ebayCaEnabled} render={(props) => <EbayShipping {...props} app={appType.ebayCA} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-ca/returns' isAuthorised={ebayCaEnabled} render={(props) => <Returns {...props} app={appType.ebayCA} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-ca/feedback' isAuthorised={ebayCaEnabled} render={(props) => <EbayFeedback {...props} app={appType.ebayCA} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-ca/inventory-locations' isAuthorised={ebayCaEnabled} render={(props) => <InventoryLocations {...props} app={appType.ebayCA} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-ca/auto-item-specifics' isAuthorised={ebayCaEnabled} render={(props) => <ItemSpecifics {...props} app={appType.ebayCA} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-ca/custom-rules' isAuthorised={ebayCaEnabled} render={(props) => <CustomRules {...props} app={appType.ebayCA} />} />
            <AuthorisedRoute exact path='/marketplaces/ebay-ca/products' isAuthorised={ebayCaEnabled} render={(props) => <EbayProducts {...props} app={appType.ebayCA} />} />
            <Route exact path='/install/ebay-ca' render={(props) => <EbayInstall {...props} app={appType.ebayCA} />} />

            {/* Other */}
            <Route exact path="/unexpected-error" component={Error500Page} />
            <Route component={Error404Page} />

        </Switch>

    );
}

export default withRouter(Routes);
